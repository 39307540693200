//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Popup from "@/components/Popup";
import EditPrinter from "@/components/management/printers/EditPrinter";
import CreatePrinter from "@/components/management/printers/CreatePrinter";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";

const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    EditPrinter,
    CreatePrinter,
    Popup,
    Layout,
    PageHeader,
  },
  data() {
    return {
      printers: [],
      selectedFranchises: [],
      searchString: '',
      editPrinter: {},
      addPrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editPrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      removePrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      showNotesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close')
      },
      clickedNotes: "",
      deletedPrinterId: 0,
      currency_list: [],
      //metro_stations: [],
      timezones: {},
      franchises: [],
      legal_relations: [],
      submitted: false,
      submitEditPrinter: 0,
      submitCreatePrinter: 0,
      title: this.$t('menuitems.management.list.printers'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.printers'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {
    computedPrinters(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.printers){
        if(searchStr == ''){
          result[result.length] = this.printers[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          let serialNumber = ''+this.printers[key].serial_number +'';
          if(regexp.test(serialNumber)){
            isSearched = true;
            result[result.length] = this.printers[key];
          }
          if(!isSearched){
            let ip = ''+this.printers[key].ip +'';
            if(regexp.test(ip)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let code = ''+this.printers[key].code +'';
            if(regexp.test(code)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let address = ''+this.printers[key].printerLocation.address +'';
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let name = ''+this.printers[key].printerLocation.name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    closeShowNotesPopup(){
      this.showNotesPopup.show = false;
    },
    showShowNotesPopup(notes){
      this.clickedNotes = notes;
      this.showNotesPopup.show = true;
    },
    closeAddPopup(){
      this.addPrinterPopup.show = false;
    },
    showAddPopup(){
      this.addPrinterPopup.show = true;
    },
    addPopupSubmit(){
      this.submitCreatePrinter++;
    },
    createPrinter(data){
      let formData = new FormData();
      for(let key in data){
        if( typeof data[key] === 'object'){
          formData.append(key, JSON.stringify(data[key]));
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/printers/create`, formData)
          .then(() => {
            this.closeAddPopup();
            this.getPrinters();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    closeRemovePopup(){
      this.removePrinterPopup.show = false;
    },
    showRemovePopup(printerId){
      this.deletedPrinterId = printerId;
      this.removePrinterPopup.show = true;
    },
    removePopupSubmit(){
      let formData = new FormData();
      formData.append("printer_id", this.deletedPrinterId);
      axios
          .post(`/v1/printers/delete`, formData)
          .then(() => {
            this.closeRemovePopup();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
            this.getPrinters();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    savePrinter(val){
      let formData = new FormData();
      for(let key in val){
        if( typeof val[key] === 'object'){
          formData.append(key, JSON.stringify(val[key]));
        }else{
          formData.append(key, val[key]);
        }
      }
      axios
          .post(`/v1/printers/update`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getPrinters();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editPopupSubmit(){
      this.submitEditPrinter++;
    },
    showEditPopup(obj){
      let copyObj = copyArrObj.copy(obj);
      this.editPrinter = copyObj;
      this.editPrinterPopup.show = true;
    },
    closeEditPopup(){
      this.editPrinterPopup.show = false;
    },
    getPrinters(){
      let formData = new FormData();
      formData.append("selected_franchises", JSON.stringify(this.selectedFranchises));
      axios
          .post(`/v1/printers/get-all`, formData)
          .then(resp => {
            this.printers = resp.data;
            for(let key in this.printers){
              this.printers[key].printerLocation.metro_station_id = '' + this.printers[key].printerLocation.metro_station_id + '';
            }
            this.getCurrencyList();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getCurrencyList(){
      axios
          .get(`/v1/configs/currency`)
          .then(resp => {
            this.currency_list = resp.data;
            //this.getMetroStations();
            this.getTimezones();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    // getMetroStations(){
    //   axios
    //       .get(`/v1/configs/metro-stations`)
    //       .then(resp => {
    //         this.metro_stations = resp.data;
    //         this.getTimezones();
    //       })
    //       .catch(err => {
    //         this.$store.dispatch("addNotification", {
    //           text: err.response.data.message,
    //           time: 6,
    //           color: "danger"
    //         });
    //       });
    // },
    getTimezones(){
      axios
          .get(`/v1/configs/timezones`)
          .then(resp => {
            this.timezones = resp.data;
            //this.getFranchisies();
            this.getLegalRelations();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getFranchisies(){
      axios
          .get(`/v1/franchise/get-all`)
          .then(resp => {
            this.franchises = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getLegalRelations(){
      axios
          .get(`/v1/legal-relations/get-all`)
          .then(resp => {
            this.legal_relations = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getPrinters();
  }
};
