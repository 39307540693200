//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PrinterPrice",
  data() {
    return {
      price: {
        COLOR_A0: '',
        COLOR_A1: '',
        COLOR_A2: '',
        COLOR_A3: '',
        COLOR_A4: '',

        COLOR_DUPLEX_A0: '',
        COLOR_DUPLEX_A1: '',
        COLOR_DUPLEX_A2: '',
        COLOR_DUPLEX_A3: '',
        COLOR_DUPLEX_A4: '',

        GRAYSCALE_A0: '',
        GRAYSCALE_A1: '',
        GRAYSCALE_A2: '',
        GRAYSCALE_A3: '',
        GRAYSCALE_A4: '',

        GRAYSCALE_DUPLEX_A0: '',
        GRAYSCALE_DUPLEX_A1: '',
        GRAYSCALE_DUPLEX_A2: '',
        GRAYSCALE_DUPLEX_A3: '',
        GRAYSCALE_DUPLEX_A4: ''
      }
    };
  },
  props: {
    default: Object,
    isProduction: Boolean,
  },
  computed: {},
  watch: {
    price: function(){
      this.emitComponent();
    },
    default: function(val){
      this.price = val;
    },
    isProduction: function(val){
      if(!val){
        this.price.COLOR_A3 = '';
        this.price.COLOR_DUPLEX_A3 = '';
        this.price.GRAYSCALE_A3 = '';
        this.price.GRAYSCALE_DUPLEX_A3 = '';
      }
    },
  },
  methods: {
    emitComponent(){
      this.$emit('emitComponent', this.price);
    }
  },
  created() {}
};
