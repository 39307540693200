//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, maxValue, minLength, minValue, required} from "vuelidate/lib/validators";
//import axios from "axios";
import WeekScheduler from "@/components/WeekScheduler";
import PrinterPrice from "@/components/management/printers/PrinterPrice";
import PrinterCustomSchedule from "./PrinterCustomSchedule.vue";
import SetterMetro from '../metro/setterMetro.vue';
//import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";

//const copyArrObj = new CopyArrayAndObject();

export default {
  name: "CreatePrinter",
  components: { PrinterPrice, WeekScheduler, PrinterCustomSchedule, SetterMetro },
  data(){
    return {
      printerData: {
        code: "",
        currency_id: 1,
        ip: "",
        is_color: 1,
        is_on: 1,
        show_map: 0,
        is_production: 0,
        login: "",
        name: null,
        password: "",
        port: "",
        notes: "",
        printerFranchiseRelation: {franch_rel_id: 0, partner_percent: null},
        printerLocation: {
          printer_id: '', 
          latitude: '', 
          longtitude: '',
          directions: '', 
          address: '', 
          launch_date: '',
          metro_station_id: "0",
          timezone: "3"
        },
        printerPrice: {
          COLOR_A3: null,
          COLOR_A4: "0",
          COLOR_DUPLEX_A3: null,
          COLOR_DUPLEX_A4: "0",
          GRAYSCALE_A3: null,
          GRAYSCALE_A4: "0",
          GRAYSCALE_DUPLEX_A3: null,
          GRAYSCALE_DUPLEX_A4: "0",
        },
        printerWorkSchedule: [],
        printerWorkScheduleCustom: [],
        serial_number: ""
      },
      submitted: false,
    }
  },
  props: {
    currencyList: Array,
    metroStations: Array,
    timezones: Object,
    //franchises: Array,
    legalRelations: Array,
    submitPrinter: Number
  },
  watch: {
    submitPrinter: function(val){
      if(val > 0){
        this.submitCreatePrinter();
      }
    }
  },
  computed: {

  },
  validations: {
    printerData: {
      serial_number: { required, minLength: minLength(3), maxLength: maxLength(35) },
      ip: { required, minLength: minLength(10), maxLength: maxLength(20) },
      port: { required, minLength: minLength(3), maxLength: maxLength(6) },
      code: { required, minLength: minLength(6), maxLength: maxLength(6) },
      login: { required, minLength: minLength(3), maxLength: maxLength(65) },
      password: { required, minLength: minLength(3), maxLength: maxLength(65) },
      name: { required, minLength: minLength(3), maxLength: maxLength(100) },
      notes: { maxLength: maxLength(350) },
      printerLocation: {
        address: { required, minLength: minLength(3), maxLength: maxLength(250) },
        directions: { required, minLength: minLength(3), maxLength: maxLength(500) },
        longtitude: { required },
        latitude: { required }
      },
      printerFranchiseRelation: {
        franch_rel_id: { required, minValue: minValue(1) },
        partner_percent: { required, minValue: minValue(0), maxValue: maxValue(100) }
      }
    }
  },
  methods: {
    setWorkSchedule(val){
      this.printerData.printerWorkSchedule = val;
    },
    setWorkScheduleCustom(val){
      this.printerData.printerWorkScheduleCustom = val;
    },
    setPrice(val){
      this.printerData.printerPrice = val;
    },
    submitCreatePrinter(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.printerData.$touch();
      if(!this.$v.printerData.$invalid){
        this.$emit('submitCreatePrinter', this.printerData);
      }
    },
  },
  created() {

  }

};
